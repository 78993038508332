<template>
  <b-button variant="flat-primary" class="datasource-select-container" ref="button">
    <div class="datasource-pointer" v-ripple.400="'rgba(57, 123, 255, 0.15)'">
      <b-media no-body>
        <b-media-aside>
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon size="18" icon="DatabaseIcon" style="color: #397bff" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="datasource-title text-uppercase">{{ $t('datasource') }}</h6>
          <small class="datasource-description">
            <span>{{ pairSelected.length }}</span>
            <span>{{ $t('item_selected') }}</span>
          </small>
        </b-media-body>
      </b-media>
      <b-badge pill variant="light-primary">
        <feather-icon size="18" icon="ChevronDownIcon" />
      </b-badge>
    </div>
    <div class="datasource-select bg-light">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 202px">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('datasource') }}</span>
              </div>
            </th>
            <th style="width: 600px">
              <div class="d-flex justify-content-between align-items-center" style="padding-right: 5px">
                <span style="line-height: 22px">{{ $t('item') }}</span>
                <div :title="$t('add')" style="min-width: 18px; cursor: pointer" @click="addPair">
                  <feather-icon size="18" icon="PlusSquareIcon" />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="custom-scrollbar">
                <div class="datasource-option" v-for="datasource in datasources" :key="datasource.id" @click="toggleDatasource(datasource.id)">
                  <div class="datasource-content">
                    <div class="datasource-name">
                      <feather-icon icon="RadioIcon" title="Live mode" v-if="datasource.live != null && datasource.type != 'netcdf'" @click.stop="changeDatasourceLiveMode(datasource)" :class="[datasource.live ? 'text-danger' : 'text-secondary']" />
                      {{ datasource.name }}
                    </div>
                    <small class="count-label" v-if="$i18n.locale === 'en'">
                      <span>Select</span>
                      <b-badge :variant="itemVariant(datasource.id)">{{ itemCount(datasource.id) }}/{{ itemTotal(datasource.id) }}</b-badge>
                      <span>Pairs</span>
                    </small>
                    <small class="count-label" v-else>
                      <b-badge :variant="itemVariant(datasource.id)">{{ itemCount(datasource.id) }}/{{ itemTotal(datasource.id) }}</b-badge>
                      <span>{{ $t('select_pairs') }}</span>
                    </small>
                  </div>
                  <b-form-checkbox :checked="datasourceSelected.some((d) => d === datasource.id) ? ['A'] : []" value="A" disabled></b-form-checkbox>
                </div>
              </div>
            </td>
            <td>
              <div class="custom-scrollbar" style="overflow-y: scroll">
                <div class="scatter-option" v-for="pair in pairSelected" :key="pair.id">
                  <div class="scatter-option-select">
                    <vue-select :options="options" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" placeholder="X" :value="pair.x" @input="selectX(pair.id, $event)">
                      <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                    </vue-select>
                  </div>
                  <div class="scatter-option-select">
                    <vue-select :options="options" :reduce="(data) => data.value" class="select-size-sm" :appendToBody="true" placeholder="Y" :value="pair.y" @input="selectY(pair.id, $event)">
                      <template #no-options>{{ $t('sorry_no_matching_options') }}</template>
                    </vue-select>
                  </div>
                  <div class="text-danger" style="min-width: 18px; cursor: pointer" @click="deletePair(pair.id)" :title="$t('delete')">
                    <feather-icon size="18" icon="Trash2Icon" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import { v4 as uuidv4 } from 'uuid';
export default {
  props: ['value'],
  directives: { Ripple },
  mounted() {
    this.autoPickDatasource();
  },
  data() {
    return {
      datasourceSelected: [],
      options1: null,
      options2: null,
    };
  },
  computed: {
    pairSelected: {
      get() {
        return this.value;
      },
      set(pairSelected) {
        this.$emit('input', pairSelected);
      },
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries');
    },
    pairs() {
      let pairs = [];
      let idTime = '';
      this.datasourceSelected.forEach((d) => {
        let datasource = this.datasources.find((s) => s.id === d);
        if (!datasource) return;
        idTime += d + '|';
        pairs.push(...Object.keys(this.$db[datasource.id].items).map((key) => `${datasource.id} : ${key}`));
      });
      if (pairs.length > 0) {
        pairs.unshift(idTime + ' : ' + 'TIME  ⏰'); // add paris item ='2b9dde8b-2874-4850-92c4-53063eba3e4b|.... : TIME'
      }
      return pairs;
    },
    options() {
      let options = this.pairs.map((p) => {
        let key = p.split(' : ')[1];
        if (key === 'TIME  ⏰') key = this.$t('time') + '  ⏰';
        return { value: p, label: key };
      });
      return options;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.changeTitle();
    },
    datasources(datasources) {
      this.datasourceSelected = this.datasourceSelected.filter((d) => datasources.some((s) => s.id === d));
    },
    pairs(pairs) {
      let pairSelected = [];
      let idTime = null;
      if (pairs.length > 0) {
        idTime = pairs[0].split(' : ')[0] + ' : TIME  ⏰';
      }
      this.pairSelected.forEach((p) => {
        let pairX = pairs.includes(p.x) ? p.x : null;
        let pairY = pairs.includes(p.y) ? p.y : null;
        if (p.x && p.x.split(' : ')[1] == 'TIME  ⏰') {
          pairX = idTime;
        }
        if (p.y && p.y.split(' : ')[1] == 'TIME  ⏰') {
          pairY = idTime;
        }
        pairSelected.push({ ...p, x: pairX, y: pairY });
      });
      this.pairSelected = pairSelected;
    },
    pairSelected() {
      this.autoPickDatasource();
    },
  },
  methods: {
    autoPickDatasource() {
      /**
       * khi restore state (load project)
       * tự động chọn các datasource có trong pairSelected
       */
      let datasources = [];
      this.pairSelected.forEach((p) => {
        if (p.x) datasources.push(p.x.split(' : ')[0]);
        if (p.y) datasources.push(p.y.split(' : ')[0]);
      });
      let distinctDatasources = [...new Set(datasources)];
      distinctDatasources.forEach((d) => {
        let dTemp = d.split('|');
        if (dTemp.length > 1) {
          for (let k = 0; k < dTemp.length - 1; k++) {
            if (!this.datasourceSelected.includes(dTemp[k])) {
              this.datasourceSelected.push(dTemp[k]);
            }
          }
        } else {
          if (!this.datasourceSelected.includes(d)) {
            this.datasourceSelected.push(d);
          }
        }
      });
    },
    toggleDatasource(id) {
      let index = this.datasourceSelected.findIndex((d) => d === id);
      if (index === -1) {
        this.datasourceSelected.push(id);
      } else {
        this.datasourceSelected.splice(index, 1);
      }
    },
    addPair() {
      this.pairSelected.push({ id: uuidv4(), x: null, y: null });
    },
    deletePair(id) {
      this.pairSelected = this.pairSelected.filter((p) => p.id !== id);
    },
    changeTitle() {
      let buttonClear = document.querySelectorAll('.vs__clear');
      for (let i = 0; i < buttonClear.length; i++) {
        if (this.locale !== 'en') buttonClear[i].title = 'クリア';
        else buttonClear[i].title = 'Clear Selected';
      }
    },
    selectX(id, value) {
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, x: value });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
      this.changeTitle();
    },
    selectY(id, value) {
      let index = this.pairSelected.findIndex((p) => p.id === id);
      let oldPair = this.pairSelected[index];
      this.pairSelected.splice(index, 1, { ...oldPair, y: value });
      this.$nextTick(() => {
        this.$refs.button.focus(); // focus lại vào button nếu không dropdown sẽ bị đóng
      });
      this.changeTitle();
    },
    itemCount(datasourceId) {
      let pairs = [];
      this.pairSelected.forEach((p) => {
        if (p.x) {
          let [dX, pX] = p.x.split(' : ');
          if (dX !== datasourceId) return;
          pairs.push(pX);
        }
        if (p.y) {
          let [dY, pY] = p.y.split(' : ');
          if (dY !== datasourceId) return;
          pairs.push(pY);
        }
      });
      return [...new Set(pairs)].length;
    },
    itemTotal(datasourceId) {
      if (!this.$db[datasourceId]) return 0;
      return Object.keys(this.$db[datasourceId].columns).length;
    },
    itemVariant(datasourceId) {
      let count = this.itemCount(datasourceId);
      let total = this.itemTotal(datasourceId);
      if (count === 0) return 'light-secondary';
      if (count === total) return 'light-success';
      return 'light-primary';
    },
    changeDatasourceLiveMode(datasource) {
      datasource.live = !datasource.live;
      this.$store.commit('datasource/UPDATE_DATASOURCE', datasource);
    },
  },
};
</script>

<style scoped>
.datasource-select-container {
  padding: 0;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}
.datasource-select-container:disabled .datasource-pointer {
  cursor: not-allowed !important;
}
.dark-layout .datasource-select-container {
  color: #d0d2d6;
}
.datasource-select-container .datasource-pointer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.datasource-select-container .datasource-title {
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.datasource-select-container .datasource-description {
  color: #397bff;
}
.datasource-select-container .datasource-select {
  position: absolute;
  left: 0;
  top: 100%;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 2;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 832px;
  transition: transform 0.4s, opacity 0.4s;
  margin-top: 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}
/* .dark-layout .datasource-select-container .datasource-select {
  background-color: #161d31 !important;
} */
.datasource-select-container:focus .datasource-select {
  transform: scale(1);
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select {
  transform: scale(1);
  opacity: 1;
}
.datasource-select-container:focus .datasource-select:hover {
  opacity: 1;
}
.datasource-select-container:focus-within .datasource-select:hover {
  opacity: 1;
}
.datasource-select-container table {
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
}
table {
  background-color: #fff;
}
.datasource-select-container th {
  padding: 10px;
  border: none;
  text-align: left;
  text-transform: uppercase;
}
.datasource-select-container td {
  padding: 0;
  border: none;
  text-align: left;
  max-width: 200px;
  vertical-align: top;
  border-right: 2px solid #f3f2f7 !important;
}
.dark-layout .datasource-select-container td {
  border-right: 2px solid #343d55 !important;
}
.datasource-select-container td:last-child {
  border-right: none !important;
}
.datasource-select-container .datasource-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.datasource-select-container .datasource-option:hover {
  background-color: #eeedfd;
}
.datasource-select-container .datasource-option.active {
  background-color: #d8dffd;
}
.dark-layout .datasource-select-container .datasource-option:hover {
  background-color: #31375a;
}
.dark-layout .datasource-select-container .datasource-option.active {
  background-color: #323f6e;
}
.datasource-select-container .datasource-option .datasource-content {
  flex-grow: 1;
  min-width: 0;
}
.datasource-select-container .datasource-option .datasource-content .datasource-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.datasource-select-container .scatter-option {
  padding: 10px;
  display: flex;
  align-items: center;
}
.datasource-select-container .scatter-option-select {
  margin-right: 5px;
  flex-grow: 1;
  flex-basis: 0;
}
.datasource-select-container .count-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.datasource-select-container .custom-scrollbar {
  min-height: 52px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
